// i18next-extract-mark-ns-start installment-payments-for-wix

//Landing redirected to /payment-methods to hide from BdE license - Discussed with David and Alex on 2024-08-08

//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import wix_cofidis from 'images/wix_cofidis.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="installment-payments-for-wix"
        title={t('Wix Buy Now Pay Later (BNPL)')}
        description={t(
          'Boost customer satisfaction and sales with Wix buy now, pay later (BNPL) options. Let customers pay in 4 installments thanks to MONEI and Cofidis 4xcard.'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Accept Wix buy now, pay later (BNPL)</Trans>
              </SectionHeader>
              <Trans parent="p">
                Adding Wix buy now, pay later installments to your checkout page will lead to a
                better customer experience, more conversions, and improved retention rates. But
                you’ll need a payment gateway that supports buy now, pay later, and integrates with
                the Wix e-commerce platform.{' '}
              </Trans>
              <Trans parent="p">
                That’s where we come in. We’re the advanced e-commerce payment gateway that lets you
                accept the widest range of online payment methods in your Wix store. Thanks to our
                partnership with Cofidis, you can configure Cofidis 4xcard to let your customers pay
                in 4 installments while you always get paid in full.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage src={wix_cofidis} alt="Wix cofidis" title="Wix cofidis" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Boost trust and increase sales with buy now, pay later for Wix</Trans>
              </SectionHeader>
              <Trans parent="p">
                Trusted and used by many,{' '}
                <InternalPageLink slug="payment-methods/cofidis">Cofidis 4xcard</InternalPageLink>{' '}
                is Spain’s leader in consumer credit. Configure the Wix buy now, pay later payment
                method to give shoppers the option to pay in 4 installments over a 90 day period.
                You’ll receive the full payment value one day after the order is placed, and with
                Cofidis 4xcard you’ll never have to chase customers for money.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Buy now, pay later with Cofidis 4xcard: how it works</Trans>
              </SectionHeader>
              <Trans parent="p">
                After you create your MONEI account and{' '}
                <AnchorLink href="https://support.monei.com/hc/articles/4402319128465">
                  configure the Cofidis payment method
                </AnchorLink>{' '}
                , your customers can choose to pay in 4 installments for purchases from €75 to
                €1,000.
              </Trans>
              <Trans parent="p">
                For consumers, the{' '}
                <InternalPageLink slug="cofidis">payment process is simple</InternalPageLink>. All
                they have to do is provide their personal ID and credit card details. They’ll be
                able to complete their online application in less than three minutes.
              </Trans>
              <Trans parent="p">
                The first transaction takes place at the time of purchase, and the remaining
                payments happen automatically 30, 60, and 90 days after the order was placed. You’ll
                get paid in full one day after the first transaction.
              </Trans>
              <Trans parent="p">
                For more information about enabling buy now, pay later with Cofidis 4xcard, and when
                you’ll get paid,{' '}
                <AnchorLink href="https://support.monei.com/hc/sections/360006008278">
                  go here
                </AnchorLink>
                .
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Why use MONEI’s payment gateway?</Trans>
              </SectionHeader>
              <Trans parent="p">
                As an online merchant, it’s vital to accept all the most popular online payment
                methods. Aside from <BlogLink slug="buy-now-pay-later">buy now, pay later</BlogLink>{' '}
                options such as Cofidis 4xcard, shoppers demand more ways to pay online. This
                includes <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>, and{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>{' '}
                as well as more local payment methods such as{' '}
                <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in Spain.
              </Trans>
              <Trans parent="p">
                That’s why we combine all major and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                into one easy-to-use merchant dashboard. By accepting more payment methods, you’ll
                improve the checkout experience, reach more people, and increase your sales revenue.
              </Trans>
              <Trans parent="p">
                Choose a payment gateway that grows with your business. Our scaled pricing means as
                you sell more, your transaction fees will decrease in real-time. This way, you can
                save money to reinvest in your e-commerce business. View our{' '}
                <InternalPageLink slug="pricing">dynamic pricing here</InternalPageLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Connect with MONEI to accept Wix buy now, pay later</Trans>
            </SectionHeader>
            <Trans parent="p">
              Easily{' '}
              <InternalPageLink slug="wix-payment-gateway">
                integrate your Wix store with our payment gateway
              </InternalPageLink>{' '}
              to start accepting buy now, pay later (and more payment methods) today.
            </Trans>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/es-es/articles/4402319128465-How-do-I-activate-Cofidis-installment-payments-">
                  Configure Installment Payments by Cofidis
                </AnchorLink>{' '}
                in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                Follow the{' '}
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/wix/">
                  Wix integration guide
                </AnchorLink>{' '}
                to connect MONEI to your online store
              </Trans>
              <Trans parent="li">
                Review our{' '}
                <AnchorLink href="https://support.monei.com/hc/en-us/articles/4406122571025-How-to-enable-MONEI-s-payment-gateway-in-Wix-">
                  Wix support article here
                </AnchorLink>
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "installment-payments-for-wix"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
